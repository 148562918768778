<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        <div class="d-flex align-center" style="gap: 16px">
          <NavButton />
          <h2 class="text-h6 text-uppercase font-weight-bold">
            Procesos de compra por subasta
          </h2>
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div style="width: 100%; margin: 0">
          <filtros-sticky icono="mdi-filter-settings" :modo-sticky="false">
            <v-row align="center">
              <v-col cols="12" sm="12" md="4">
                <v-text-field
                  label="Código / Nombre de proceso"
                  outlined
                  clearable
                  hide-details
                  v-model="filtros.nombre"
                />
              </v-col>

              <v-col cols="12" sm="12" md="4">
                <v-autocomplete
                  label="Modalidad de compra"
                  :items="ctlModalidades"
                  item-value="id"
                  item-text="nombre"
                  outlined
                  clearable
                  hide-details
                  v-model="filtros.id_forma_contratacion"
                />
              </v-col>

              <v-col cols="12" sm="12" md="4">
                <v-autocomplete
                  label="Estado"
                  :items="itemsEstados"
                  item-value="id"
                  item-text="nombre"
                  outlined
                  hide-details
                  clearable
                  v-model="filtros.id_estado_seguimiento"
                />
              </v-col>

              <v-col cols="12" sm="12" md="4">
                <v-menu
                  ref="rangoFechas"
                  v-model="rangoFechas"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      v-model="rangoFechasTexto"
                      label="Desde - Hasta"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <vc-date-picker
                    title="es"
                    is-range
                    v-model="fechas"
                    mode="date"
                    locale="es"
                    @input="setFiltrosFechas"
                  >
                  </vc-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="12" md="4">
                <v-autocomplete
                  label="Año fiscal"
                  :items="itemsAnios"
                  item-value="id"
                  item-text="anio"
                  outlined
                  hide-details
                  clearable
                  v-model="filtros.id_anio_fiscal"
                />
              </v-col>

              <v-col cols="12" md="4">
                <div
                  class="d-flex justify-center align-center flex-wrap-reverse flex-md-nowrap"
                  style="gap: 16px"
                >
                  <v-btn
                    color="primary"
                    class="flex-grow-1 flex-shrink-1"
                    x-large
                    @click.stop="hacerBusquedaConFiltros"
                  >
                    Buscar
                  </v-btn>

                  <v-btn
                    color="primary"
                    class="flex-grow-1 flex-shrink-1"
                    outlined
                    x-large
                    @click.stop="restablecerBusqueda"
                  >
                    Limpiar
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </filtros-sticky>

          <data-table-component
            class="mt-4"
            :items="procesos.data"
            :show_loading="procesos.isLoading"
            :headers="headers"
            :total_registros="registrosTotales"
            @paginar="manejarPaginacion"
            v-models:pagina="paginacion.pagina"
            v-models:select="paginacion.registrosPorPagina"
            dense
            no-gutters
          >
            <template #[`item.fecha_inicio_proceso`]="{ item }">
              <span>
                {{ moment(item.fecha_inicio_proceso).format("MMMM / YYYY") }}
              </span>
            </template>
            <template #[`item.institucion`]="{ item }">
              <span>
                {{ item.Institucion?.codigo }} -
                {{ item.Institucion?.nombre }}
              </span>
            </template>
            <template #[`item.fecha_contratacion`]="{ item }">
              <span>
                {{ moment(item.fecha_contratacion).format("DD/MM/YYYY") }}
              </span>
            </template>
            <template #[`item.monto_total`]="{ item }">
              {{ toMoneyFormat(item.monto_total) }}
            </template>
            <template #[`item.estado`]="{ item }">
              <v-chip
                :color="getEstadoProceso(item).color"
                label
                class="mt-1"
                :dark="item.EstadoProceso.id === 1"
              >
                {{ getEstadoProceso(item).nombre }}
              </v-chip>

              <p
                v-if="item.EstadoProceso.id === 4 && item.id_tipo_proceso === 2"
              >
                Este proceso está en construcción, en procesos con reserva
              </p>
            </template>
            <template #[`item.acciones`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="irDetalleProceso(item)"
                    v-bind="attrs"
                    v-on="on"
                    color="secondary"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Ver seguimiento</span>
              </v-tooltip>
            </template>
          </data-table-component>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import FiltrosSticky from "@/views/CmReporteria/components/FiltrosSticky.vue";
import NavButton from "@/components/utils/NavButton.vue";
import moment from "moment";
import { toMoneyFormat } from "@/utils/data";
import {
  createPageable,
  togglePageable,
  setPageableResponse,
} from "@/utils/loadable";

const FILTROS_INICIALES = {
  nombre: "",
  id_estado_seguimiento: null,
  fecha_desde: null,
  fecha_hasta: null,
  id_anio_fiscal: null,
};

export default {
  name: "PACSubastaListView",
  components: {
    DataTableComponent,
    FiltrosSticky,
    NavButton,
  },
  data() {
    return {
      // Filtros
      filtros: {
        ...FILTROS_INICIALES,
      },
      rangoFechas: false,
      fechas: { start: null, end: null },
      itemsAnios: [],
      // Procesos de compra
      paginacion: {
        pagina: 1,
        registrosPorPagina: 10,
      },
      procesos: createPageable([], 10),
      totalProcesos: 0,
      ctlModalidades: [],
    };
  },
  computed: {
    itemsEstados() {
      return [
        {
          id: 1,
          nombre: "Configuración proceso",
        },
        {
          id: 11,
          nombre: "Recepción de solicitudes",
        },
        {
          id: 3,
          nombre: "En ejecución",
        },
        {
          id: 5,
          nombre: "Contratación",
        },
        {
          id: 44,
          nombre: "Desierto",
        },
        {
          id: 77,
          nombre: "Sin Efecto",
        },
        {
          id: 79,
          nombre: "Suspendido",
        },
        {
          id: 80,
          nombre: "Seguimiento contractual",
        },
        {
          id: 81,
          nombre: "Liquidado",
        },
        {
          id: 90,
          nombre: "Recurso de revisión",
        },
        {
          id: 91,
          nombre: "Recurso de apelación",
        },
      ];
    },
    rangoFechasTexto() {
      return this.fechas && this.fechas.start && this.fechas.end
        ? moment(this.fechas.start).format("D/M/Y H:mm") +
            " - " +
            moment(this.fechas.end).format("D/M/Y H:mm")
        : "Selecciona un rango de fechas";
    },
    registrosTotales() {
      return this.procesos.pagination.total_rows;
    },
    headers() {
      return [
        {
          text: "Código",
          value: "codigo_proceso",
          sortable: false,
        },
        {
          text: "Código interno",
          value: "codigo_interno",
          sortable: false,
        },
        {
          text: "Proceso",
          value: "nombre_proceso",
          sortable: false,
        },
        {
          text: "Modalidad de compra",
          value: "FormaContratacion.nombre",
          align: "left",
          sortable: false,
        },
        {
          text: "Institución",
          value: "institucion",
          align: "left",
          sortable: false,
        },
        {
          text: "Mes programado",
          value: "fecha_inicio_proceso",
          align: "right",
          sortable: false,
        },
        {
          text: "Fecha solicitado",
          value: "fecha_contratacion",
          align: "right",
          sortable: false,
        },
        {
          text: "Monto planificado($)",
          value: "monto_total",
          align: "right",
          sortable: false,
        },
        {
          text: "Estado",
          value: "estado",
          align: "center",
          sortable: false,
        },
        {
          text: "Acciones",
          value: "acciones",
          align: "center",
          sortable: false,
        },
      ];
    },
    getEstadoProceso() {
      return (item) => {
        if (item.EstadoProceso.id === 1) {
          return {
            color: item.SeguimientoProceso?.color || "default",
            nombre: item.SeguimientoProceso?.nombre || "",
          };
        }

        return {
          color: this.obtenerColorProcesoEstado(item.EstadoProceso.id),
          nombre: item.EstadoProceso.nombre,
        };
      };
    },
  },
  methods: {
    toMoneyFormat,
    setFiltrosFechas() {
      const { start, end } = this.fechas || {};
      this.filtros.fecha_desde = start || null;
      this.filtros.fecha_hasta = end || null;
    },
    async obtenerAniosFiscales() {
      const { data } = await this.services.PacProcesos.cargarAniosFiscales();
      this.itemsAnios = data;
    },
    obtenerColorProcesoEstado(id) {
      switch (id) {
        case 5:
          return "error";
        case 6:
          return "amber lighten-3";
        case 7:
          return "purple lighten-2";
        case 8:
          return "info";
        case 9:
          return "#51b0ff";
        default:
          return "default";
      }
    },
    async obtenerListadoProcesos() {
      const filtros = {
        pagination: true,
        per_page: this.paginacion.registrosPorPagina,
        page: this.paginacion.pagina,
        ...this.filtros,
      };

      togglePageable(this.procesos);

      const { data, headers } =
        await this.services.Paac.getListProcesosSubastas(filtros);

      setPageableResponse(this.procesos, data, headers);

      console.log("this.procesos", this.procesos);
    },
    async manejarPaginacion(paginacion) {
      const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
      this.paginacion = { pagina, registrosPorPagina };
      await this.obtenerListadoProcesos();
    },
    async initData() {
      await this.obtenerAniosFiscales();
      await this.obtenerListadoProcesos();
      await this.fetchModalidades();
    },
    async hacerBusquedaConFiltros() {
      this.paginacion.pagina = 1;
      await this.obtenerListadoProcesos();
    },
    async restablecerBusqueda() {
      this.filtros = { ...FILTROS_INICIALES };
      this.fechas = { start: null, end: null };
      this.rangoFechas = false;
      this.paginacion = { pagina: 1, registrosPorPagina: 10 };
      await this.obtenerListadoProcesos();
    },
    irDetalleProceso(proceso) {
      window.open(
        `${location.origin}/procesos-compra-subasta/${proceso.id}`,
        "_blank"
      );
    },
    async fetchModalidades() {
      const {
        data: { forma_contratacion },
      } = await this.services.Paac.getModalidades({
        compra_tradicional: true,
      });
      this.ctlModalidades = forma_contratacion;
    },
  },
  created() {
    this.initData();
  },
};
</script>
